import React from "react"
import { Router } from "@reach/router"
import { useNavigate } from "@reach/router"
import { Link, withPrefix } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./contact.module.scss"

const Contact = class extends React.Component {
  state = {
    companyName: "",
    companyAddress: "",
    name: "",
    email: "",
    tel: "",
    content: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.displayData(this.state)
    this.sendData(this.state).then(
      response => {
        console.log(response)
      },
      error => {
        console.error(error)
      }
    )
  }

  displayData = data => {
    console.log(data)
  }

  sendData = data => {
    return new Promise(function (resolve, reject) {
      const XHR = new XMLHttpRequest()
      const FD = new FormData()

      for (const name in data) {
        FD.append(name, data[name])
      }

      XHR.open("POST", withPrefix("/php/mail.php"))
      XHR.send(FD)

      XHR.onload = () => {
        resolve(`SUCCESS!: ${XHR.response}`)
      }

      XHR.onerror = () => {
        reject(`ERROR: ${XHR.statusText}`)
      }
    })
  }

  render() {
    return (
      <PageLayout title="お問合せ" enTitle="CONTACT">
        <SEO title="お問合せ" />
        <Router basepath={withPrefix("/contact")}>
          <Form
            path="/"
            state={this.state}
            handleInputChange={this.handleInputChange}
          />
          <Confirm
            path="/confirm"
            state={this.state}
            handleSubmit={this.handleSubmit}
          />
          <Complete path="/complete" />
        </Router>
      </PageLayout>
    )
  }
}

const Form = props => {
  const navigate = useNavigate()
  return (
    <form
      className={Style.form}
      onSubmit={event => {
        event.preventDefault()
        navigate(withPrefix("/contact/confirm"))
      }}
    >
      <label>
        <span>
          貴社名<span className={Style.required}>（必須）</span>
        </span>
        <input
          type="text"
          name="companyName"
          value={props.state.companyName}
          onChange={props.handleInputChange}
          required="required"
        />
      </label>
      <label>
        <span>
          貴社住所<span className={Style.required}>（必須）</span>
        </span>
        <input
          type="text"
          name="companyAddress"
          value={props.state.companyAddress}
          onChange={props.handleInputChange}
          required="required"
        />
      </label>
      <label>
        <span>
          お名前<span className={Style.required}>（必須）</span>
        </span>
        <input
          type="text"
          name="name"
          value={props.state.name}
          onChange={props.handleInputChange}
          required="required"
        />
      </label>
      <label>
        <span>
          E-mail<span className={Style.required}>（必須）</span>
        </span>
        <input
          type="email"
          name="email"
          value={props.state.email}
          onChange={props.handleInputChange}
          required="required"
        />
      </label>
      <label>
        電話番号
        <input
          type="tel"
          name="tel"
          value={props.state.tel}
          onChange={props.handleInputChange}
        />
      </label>
      <label>
        <span>
          お問合せ内容<span className={Style.required}>（必須）</span>
        </span>
        <textarea
          name="content"
          rows="5"
          value={props.state.content}
          onChange={props.handleInputChange}
          required="required"
        />
      </label>
      <button type="submit" className={Style.submit}>
        確認
      </button>
    </form>
  )
}

const Confirm = props => {
  const navigate = useNavigate()
  return (
    <form
      className={Style.form}
      onSubmit={event => {
        props.handleSubmit(event)
        navigate(withPrefix("/contact/complete"))
      }}
    >
      <p>
        <Link to="/contact" className={Style.back}>
          戻る
        </Link>
      </p>
      <label>
        <span>貴社名</span>
        {props.state.companyName}
        <input
          type="hidden"
          name="companyName"
          value={props.state.companyName}
          required="required"
        />
      </label>
      <label>
        <span>貴社住所</span>
        {props.state.companyAddress}
        <input
          type="hidden"
          name="companyAddress"
          value={props.state.companyAddress}
          required="required"
        />
      </label>
      <label>
        <span>お名前</span>
        {props.state.name}
        <input
          type="hidden"
          name="name"
          value={props.state.name}
          required="required"
        />
      </label>
      <label>
        <span>E-mail</span>
        {props.state.email}
        <input
          type="hidden"
          name="email"
          value={props.email}
          required="required"
        />
      </label>
      <label>
        <span>電話番号</span>
        {props.state.tel}
        <input type="hidden" name="tel" value={props.state.tel} />
      </label>
      <label>
        <span>お問合せ内容</span>
        {props.state.content}
        <input
          type="hidden"
          name="content"
          rows="5"
          value={props.state.content}
          required="required"
        />
      </label>
      <button type="submit" className={Style.submit}>
        送信
      </button>
    </form>
  )
}

const Complete = () => {
  return (
    <div className={Style.complete}>
      <p>送信が完了しました。</p>
      <Link to="/">トップページ</Link>
    </div>
  )
}

export default Contact
